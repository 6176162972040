


























import { Medicao } from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';
import { MedicaoService } from '@/core/services/residuo';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class RelatorioMedicao extends PageBase {
    
    service = new MedicaoService();
    item: Medicao = new Medicao();
    overlay: boolean = false;

    mounted() {
        const queryfilter = this.$route.query;
        const filter = this.service.MontaFiltro(queryfilter);
        this.overlay = true;
        
        this.service.ListarTudoFiltro(filter,'',"Cliente" )
        .then(
            res=>{
                this.item = res.data.items;
            },
            err=>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() =>{
            this.overlay = false;
        })
    }


}

